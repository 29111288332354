:root {
  --editor-bg: var(--color-bggray);
  --animation-pulsing-bg: rgb(255, 255, 255, 0.5);
  --slider-background-color: var(--editor-bg);
  --logo-control-radius: 10px;

  --box-shadow: inset 0 0.5px rgba(255, 255, 255, 0.1),
    inset 0 1px 5px hsl(210 16.7% 97.6%), 0px 0px 0px 0.5px hsl(205 10.7% 78%),
    0px 2px 1px -1px hsl(205 10.7% 78%), 0 1px hsl(205 10.7% 78%);
}

@keyframes pulsing {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.75;
    background-color: var(--animation-pulsing-bg);
  }

  100% {
    opacity: 1;
  }
}

.editor-container *,
.editor-container *:before,
.editor-container *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.editor-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.editor-canvas-wrapper {
  display: grid !important;
  width: 100% !important;
  height: 100% !important;
}

.editor-canvas-container {
  display: grid;
  grid-template-areas: "editor-content";
  row-gap: 1rem;
  background-color: var(--editor-bg);
}

.editor-canvas {
  grid-area: editor-content;
  margin: auto;
  background-image:
    linear-gradient(45deg, var(--color-teal-light) 25%, transparent 25%),
    linear-gradient(-45deg, var(--color-teal-light) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--color-teal-light) 75%),
    linear-gradient(-45deg, transparent 75%, var(--color-teal-light) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.editor-canvas-container .original-image-container {
  grid-area: editor-content;
  margin: auto;
  pointer-events: none;
  display: grid;
  grid-template-areas: "original-image-content";
}

.editor-canvas-container .original-image-container img {
  grid-area: original-image-content;
}

.editor-canvas-loading {
  pointer-events: none;
  animation: pulsing 750ms infinite;
}

.brush-shape {
  position: absolute;
  border-radius: 50%;
  background-color: var(--color-primary1-main);
  border: 1px solid var(--color-primary1-80);
  pointer-events: none;
}

.node {
  position: absolute;
  display: block;
  border-radius: var(--logo-control-radius);
  width: calc(2 * var(--logo-control-radius));
  height: calc(2 * var(--logo-control-radius));
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.5882352941);
  cursor: pointer;
}

.node:hover {
  background: #a6f4ff;
}

.logo-border {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
