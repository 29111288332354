.image-editor-popup {
    height: 100% !important;
    min-height: 200px !important;
    max-height: calc(100% - 64px) !important;
    width: calc(100% - 64px) !important;
    padding: 32px !important;
}

.image-editor-header {
    padding: 0 !important;
    margin-bottom: 1rem !important;
}

.image-editor-content {
    height: 95% !important;
}

.image-editor-header h1 {
    margin: 0px !important;
}

.image-editor-row {
    height: calc(100% - 70px) !important;
    margin-bottom: 1rem !important;
}

.editor-popup-buttons {
    position: fixed !important;
    right: 32px !important;
}

.editor-save-button {
    margin-left: 0.5rem !important;
    width: 10rem !important;
}

.editor-popup-close {
    position: fixed !important;
    right: 32px !important;
    top: 32px !important;
    cursor: pointer;
} 
