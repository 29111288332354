.editor-toolkit {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 300px;
}

.editor-toolkit > * {
  width: 100% !important;
}

.editor-toolkit > *:not(:first-child) {
  margin-top: 0.5rem !important; 
}

.toolkit-panel-button {
  cursor: pointer;
  background: var(--color-neutrals-white);
  padding: 8px 16px !important;
  border-radius: 5px;
}

.toolkit-panel-button.disabled {
  cursor: default;
  background: rgb(208, 215, 220);
}

.toolkit-panel-button.disabled * {
  color: rgb(137, 156, 169) !important;
}

.toolkit-panel-button p {
  font-size: 20px !important;
  margin-bottom: 0 !important;
}

.toolkit-title-row {
  gap: 0.5rem;
  font-size: 20px !important;
  align-items: center !important;
  user-select: none;
}

.toolkit-title-row * {
  color: var(--color-neutrals-90) !important;
}

.editor-toolkit-panel > .toolkit-panel-row {
  width: 100% !important;
  margin-top: 1.5rem !important;
}

.editor-toolkit-panel > .toolkit-panel-row:first-child {
  margin-top: 0 !important;
}

.toolkit-panel-row > button,
.toolkit-panel-row > .editor-brush-slider {
  width: calc(300px - 32px) !important;
}

.toolkit-panel-row ul {
  margin-bottom: 0;
}

.remove-logo-btn {
  cursor: pointer;
  color: var(--color-secondary1-main) !important;
  margin: auto !important;
}
