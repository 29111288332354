.editor-brush-slider {
    user-select: none;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    height: max-content;
    column-gap: 1rem;
    align-items: center;
}

.editor-brush-slider input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    width: calc(300px - 32px);
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.editor-brush-slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.editor-brush-slider input[type=range]:focus {
    outline: none;
}

.editor-brush-slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    border: 1px solid var(--color-primary1-80);
    z-index: 2;
    background: var(--color-primary1-main);
    margin-top: -0.5rem;
}

.editor-brush-slider input[type=range]::-webkit-slider-runnable-track {
    border-radius: 2rem;
    height: 0.2rem;
    background: var(--slider-background-color);
}

.editor-brush-slider input[type=range]::-moz-range-track {
    border-radius: 2rem;
    background: var(--slider-background-color);
}

.editor-brush-slider input[type=range]::-moz-range-progress {
    background: var(--color-primary1-main);
}
