body.csku-react {
  margin: 0; }
  body.csku-react.csku-dashboard {
    width: 100%;
    margin: 0;
    padding-left: 185px; }
    @media only screen and (max-width: 800px) {
      body.csku-react.csku-dashboard .sidebar {
        display: block; } }
    @media only screen and (max-width: 40em) {
      body.csku-react.csku-dashboard {
        padding-left: 10px; } }
    body.csku-react.csku-dashboard .sidebar ul {
      font-weight: normal; }
  body.csku-react .csku-deprecated {
    display: none !important; }
  body.csku-react #root.resku {
    font-family: "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    width: 1200px; }
    body.csku-react #root.resku.csku-dashboard > div {
      height: 83px; }
      body.csku-react #root.resku.csku-dashboard > div > .header-container .header {
        max-width: none;
        width: 100%;
        left: 0; }
      body.csku-react #root.resku.csku-dashboard > div > .sidebar {
        width: 176px;
        top: 0;
        left: 0; }
    body.csku-react #root.resku > div .header {
      left: initial; }
      @media only screen and (max-width: 1200px) {
        body.csku-react #root.resku > div .header {
          position: absolute; } }
      body.csku-react #root.resku > div .header button, body.csku-react #root.resku > div .header .button {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        border-radius: 3px;
        -ms-border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px; }
    body.csku-react #root.resku > div > .sidebar {
      position: fixed;
      width: 176px;
      top: 0;
      left: initial; }
      body.csku-react #root.resku > div > .sidebar .vertical.menu {
        /* add redundent css to overide browser default
           * should move this to global settings after migrate everything to
           * resku*/
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        font-family: "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
  body.csku-react .page-container, body.csku-react .cs-footer {
    box-sizing: border-box;
    padding: 0 0 0 192px;
    width: auto;
    margin: 0; }
    @media only screen and (max-width: 640px) {
      body.csku-react .page-container, body.csku-react .cs-footer {
        padding-left: 0; }
        body.csku-react .page-container .wrapper, body.csku-react .cs-footer .wrapper {
          padding-left: 0; } }
    body.csku-react .page-container .wrapper, body.csku-react .cs-footer .wrapper {
      padding-left: 15px;
      margin: 0; }
  body.csku-react .page-container > .wrapper > div.page {
    padding-top: 0; }
  body.csku-react .page-container #order_footer {
    z-index: 1100; }
  body.csku-react .cs-footer {
    border-top: 1px solid #ddd;
    background: transparent;
    color: #424242; }
    body.csku-react .cs-footer a {
      color: #424242; }
    body.csku-react .cs-footer .copyright {
      padding-right: 15px; }

body.admin_billing {
  background: #E6EFF2; }
